// Segment tracking for find care forms
import { trackSegmentEvent, getContextProperties, getElementProperties } from '~/components/shared/tracking/segmentAnalytics';

function getFormProperties(form) {
  const props = {};
  props.reason = form.querySelector('#reasonForVisit').value;
  props.time = form.querySelector('#appointmentTime').value;
  props.patient = form.querySelector('#patientType').value;
  return props;
}

document.querySelectorAll('[data-find-care-form]').forEach(form => {
  // set up general click segment events
  form.querySelectorAll('input, select').forEach(inputEl => {
    inputEl.dataset.segmentEvent = "Directory - Search";
    inputEl.dataset.segmentAction = "clicks " + inputEl.dataset.fieldName;
    inputEl.dataset.segmentLocation = form.dataset.formLocation;
  });
  // segment event for form submission
  form.addEventListener('submit', () => {
    const event = form.dataset.formEvent;
    const action = "click find";
    const formProperties = getFormProperties(form);
    const elementProperties = getElementProperties(form);
    const contextProperties = getContextProperties();
    trackSegmentEvent(event, { ...elementProperties, ...formProperties, ...contextProperties, action });
  })
  // mobile reveal event
  if (typeof form.dataset.mobileHidden !== 'undefined') {
    const breakpoint = form.dataset.mobileBreakpoint.replace('px', '');
    form.querySelector('#reasonForVisit').addEventListener('click', () => {
      if (window.innerWidth < parseInt(breakpoint)) {
        const event = form.dataset.formEvent;
        const action = "clicks to expand search widget";
        const contextProperties = getContextProperties();
        trackSegmentEvent(event, { ...contextProperties, action });
        form.querySelectorAll('.form-field.mobile-hide').forEach(el => {
          el.classList.remove('mobile-hide');
        })
      }
    })
  }

});
